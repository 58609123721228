import { ErrorPage } from '@strike-apps/commerce-dashboard/feature-layout'
import { useRouter } from 'next/router'

export default function NotFound() {
  const router = useRouter()

  return (
    <ErrorPage
      header="404 Page not found"
      description="The page you're looking for either doesn't exist or has moved elsewhere."
    >
      <ErrorPage.CtaButton onClick={() => router.push('/')}>Return home</ErrorPage.CtaButton>
    </ErrorPage>
  )
}
